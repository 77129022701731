import { File } from './file';

export class ProductImages {
  public id!: number;
  public position!: number;
  public productId!: number;
  public fileId!: number;
  public file: File | undefined;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.position = +data.position;
      this.productId = +data.productId;
      this.fileId = +data.fileId;

      if (data.file) {
        this.file = new File(data.file);
      }
    }
  }
}
