import { ApiServices } from '@/services/api.services';

export class File {
  public id: number | undefined;
  public name: string | undefined;
  public key: string | undefined;
  public mime: string | undefined;
  public size: number | undefined;

  public textSize: string | undefined;
  public iconFile: string | undefined;
  public urlImg: string | undefined;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.name = data.name;
      this.key = data.key;
      this.mime = data.mime;
      this.size = +data.size;

      if (this.key !== undefined) {
        this.urlImg = ApiServices.getFileImage(this.key);
      }
    }
  }
}
