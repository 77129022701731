export class ProductSubCategory {
  public id!: number;
  public name!: string;
  public categoryId!: number;

  public inputEdit = false;
  public valueToEdit = null;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.name = data.name;
      this.categoryId = +data.categoryId;
    }
  }
}
