export class ProductAddonOptions {
  public id!: number;
  public label!: string;
  public description!: string;
  public placeholder!: string;
  public info!: string;
  public price!: number;
  public typeAmount!: string;
  public addonId!: number;
  public position!: number;
  public createdAt!: string;
  public updatedAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.label = data.label;
      this.description = data.description;
      this.placeholder = data.placeholder;
      this.info = data.info;
      this.price = +data.price;
      this.typeAmount = data.typeAmount;
      this.addonId = +data.addonId;
      this.position = +data.position;

      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
    }
  }
}
