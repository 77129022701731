import { ProductCategory } from './../../class/productCategory';
import { ProductImages } from './../../class/productImages';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';
import { Product } from '@/class/product';
import { ProductSubCategory } from '@/class/productSubCategory';
import DunatiSelectComponent from '@/components/dunati-select/dunati-select.component';
import { File } from './../../class/file';
import { ProductAddonOptions } from '@/class/productAddonOptions';
import CardProductBComponent from '@/components/card-product-b/card-product-b.component';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { DUNATI_DOMAIN, DUNATI_STORE_PRODUCTS, ROBOT_CONTENT } from '@/main';


const components: any = {
  DunatiSelectComponent,
  CardProductBComponent,
  DunatiLoaderComponent
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const carousel = () => import('vue-owl-carousel');
  components.carousel = carousel;
  // @ts-ignore
  const InvisibleRecaptcha = () => import('vue-invisible-recaptcha');
  components['invisible-recaptcha'] = InvisibleRecaptcha;
}

@Component({
  components,
})
export default class ProductDetailPage extends Vue {

  public storeLink = DUNATI_STORE_PRODUCTS;
  public data = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  public emailSend = false;
  public sendingEmail = false;
  public loading = false;
  public errorEmail = false;
  public scrollable = true;
  public sendText = 'Enviar';
  public errorMsg = '';
  public privateKey = '6LchoMEUAAAAAN2Aqiy__h6yfNMXFt2-9Nu7R2pB';
  public product!: Product;
  public category!: ProductCategory;
  public subCategory!: ProductSubCategory;
  public selectedImg!: ProductImages;
  public productImages: ProductImages[] = [];
  public productImagesAll: ProductImages[] = [];
  public listPlaceholders = Array(3).fill(1);
  public loadInit: boolean = true;
  public currentTab: string = 'descripcion';
  public price = 0;
  public oldPrice = 0;
  public addons: ProductAddonOptions[] = [];
  public listRelatedProducts: Product[] = [];
  public saleImg: string = '/assets/images/product_detail/SALE.svg';
  public onSale: boolean = false;
  public namePlaceholder: string = '';
  public carouselResponsive = {
    320: {
      items: 1,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      nav: false,
    },
    767: {
      items: 1,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      nav: false,
    },
    992: {
      items: 1,
      nav: false,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
    },
    1140: {
      items: 1,
      nav: false,
    },
  };

  public mounted() {
    this.getInitData();
    window.addEventListener('touchmove', this.preventBehavior, { passive: false });
    this.$store.dispatch('changeMenu', 'bg1');
  }

  public destroyed() {
    this.$store.dispatch('changeMenu', 'normal');
    window.removeEventListener('touchmove', this.preventBehavior);
  }

  public getInitData(s: boolean = false) {
    return new Promise((resolve, reject) => {
      const data = this.$route.params.id;

      this.namePlaceholder = data.replace(/-/g, ' ');

      ApiServices.getProductBySlug(data).then((response) => {
        this.product = new Product(response.data.data.data);
        if (!this.product.webEnable) {
          this.moveTo('404');
        }
        this.category = this.product.category;
        this.subCategory = this.product.subCategory;

        this.selectedImg = this.product.productImages.shift(); // la primera img
        this.productImages = this.product.productImages; // todas menos la primera
        this.productImagesAll = this.product.productImages;
        this.productImagesAll.unshift(this.selectedImg); // todas las imgs

        this.price = this.product.unitPrice;
        this.getRelatedProducts(this.subCategory.id);

        if (this.product.webPromoPrice > 0 && (!this.product.webPromoDateInit || this.dateOnRange(this.product.webPromoDateInit, this.product.webPromoDateEnd))) {
          this.oldPrice = this.product.unitPrice;
          this.price = this.product.webPromoPrice;
          this.onSale = true;
        }

        this.$route.meta.title = `${this.product.name} - Dunati`;
        this.loadInit = false;

        this.updateTags();

        return resolve(true);
      }, (error) => {
        if (s) {
          return reject({ redirectTo: '/productos' });
        } else {
          this.$router.push('/404');
        }
      });

      if (process.env.VUE_ENV !== 'server') {
        const owl = $('#carouselImg .owl-carousel');
        owl.on('drag.owl.carousel', (event) => {
          document.ontouchmove = (e) => {
            e.preventDefault();
          };
        });

        owl.on('dragged.owl.carousel', (event) => {
          document.ontouchmove = (e) => {
            return true;
          };
        });
      }
    });
  }

  public showImg(img: ProductImages) {
    this.productImages = this.product.productImages.filter((x: any) => x.file !== img.file);
    this.productImages.push(img);
    this.productImages = this.productImages.sort((a: any, b: any) => a.position - b.position);
    this.selectedImg = img;
  }

  public getUpper(item: any) {
    if (item) {
      return item.name.toUpperCase();
    }
    return '';
  }

  public serverPrefetch() {
    return this.getInitData(true);
  }

  public dateOnRange(d1: string, d2: string) {
    const [today, init, end] = [new Date(Date.now()), new Date(d1), new Date(d2)];
    if (today >= init && today <= end) {
      return true;
    }
    return false;
  }

  public addAddon(addon: ProductAddonOptions) {
    if (this.addons.length > 0) {
      const addons = this.addons.filter((x) => x.addonId === addon.addonId);
      if (addons.length > 0) {
        this.price -= addons[0].price;
        this.price += addon.price;
        this.addons = this.addons.filter((x) => x.addonId !== addon.addonId);
        this.addons.push(addon);
      } else {
        this.price += addon.price;
        this.addons.push(addon);
      }
    } else {
      this.price += addon.price;
      this.addons.push(addon);
    }
  }

  public getRelatedProducts(subCategory: number) {
    ApiServices.getProducts(`where[subCategoryId]=${subCategory}&limit=4`).then((response) => {
      const productResponse: Product[] = response.data.data.data;
      const products: Product[] = productResponse.filter((x) => +x.id !== +this.product.id);

      products.forEach((element: Product, index: number) => {
        if (index < 4) {
          this.listRelatedProducts.push(new Product(element));
        }
      });

      const len = this.listPlaceholders.length;
      for (let i = 0; i < len; i++) {
        this.listPlaceholders.pop();
      }
    });
  }

  public dragged() {
    $('body').css('overflow', 'auto');
    this.scrollable = true;
  }

  public drag() {
    if (window.innerWidth <= 768) {
      $('body').css('overflow', 'hidden');
    }
    this.scrollable = false;
  }

  public onLoaded(id: string) {
    const cls: string = `.${id}`;
    $(cls).hide();
  }

  public moveTo(category: any) {
    let url = '';
    if (category.name) {
      url = category.name.toLowerCase();
    } else if (category === '404') {
      this.$router.push({ path: '/404' });
    } else {
      url = category.toLowerCase();
    }

    if (url === 'products') {
      this.$router.push({ name: 'products' });
    } else if (url === 'sillas') {
      this.$router.push({ path: '/sillas-de-oficina' });
    } else if (url === 'mesas') {
      this.$router.push({ path: '/mesas' });
    } else if (url === 'almacenamiento') {
      this.$router.push({ path: '/almacenamiento' });
    }
  }

  public getAlt(index: string = '') {
    return `${this.category.name} ${this.subCategory.name.toLowerCase()} de oficina, ${this.product.name.toLowerCase()} ${index + 1}`;
  }

  public getUrl(productSheet: File) {
    if (productSheet && productSheet.urlImg) {
      return productSheet.urlImg;
    } else {
      return '';
    }
  }

  public Clear() {
    this.emailSend = false;
    this.sendingEmail = false;
    this.loading = false;
    this.errorEmail = false;
    this.data = {
      name: '',
      email: '',
      phone: '',
      message: '',
    };
  }

  public Close(modal: string) {
    this.Clear();
    this.$root.$emit('bv::hide::modal', modal);
  }

  public titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public sendMail() {
    if (this.data.name !== '' && this.data.message !== '' && this.data.email !== '' && this.validEmail(this.data.email)) {
      this.sendingEmail = true;
      this.loading = true;
      const msg = `<h3>Solicitud de asesoría</h3>
      <p>De: ${this.titleCase(this.data.name)}</p>
      <p>Correo: ${this.data.email}</p>
      <p>Teléfono: ${this.data.phone || 'NA'}</p>
      <p>Link del producto: <a href="${window.location.href}">${this.product.name.toUpperCase()}</a></p>
      <br>
      <p>${this.data.message}</p>`;

      let conversionUrl = '';
      if (typeof window !== 'undefined') {
        conversionUrl = window.entryUrl;
      }


      const body = {
        notificationsFrom: `${this.titleCase(this.data.name)}`,
        msg,
        subject: `Solicitud de asesoría de: ${this.titleCase(this.data.name)}`,
        contact: {
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          name: this.titleCase(this.data.name),
          source: `productos/${this.product.slug}`,
          conversionUrl,
          extras: {
            product: `\nProducto: ${this.product.name.toUpperCase()}`,
            link: `\nLink del product: ${window.location.href}`,
          },
        },
      };

      ApiServices.sendMail(body).then((res) => {
        if (res.data) {
          this.sendText = '¡Listo!';
          this.emailSend = true;
          this.sendingEmail = false;
          this.loading = false;


          setTimeout(() => {
            this.Close('modal-contact');
          }, 10000);
        }
      });
    } else {
      this.errorEmail = true;
      if (!this.validEmail(this.data.email)) {
        this.errorMsg = 'El correo electrónico debe ser válido.';
      } else if (this.data.name === '') {
        this.errorMsg = 'El campo nombre es requerido.';
      } else if (this.data.email === '') {
        this.errorMsg = 'El campo correo es requerido.';
      } else if (this.data.message === '') {
        this.errorMsg = 'El campo mensaje es requerido.';
      }

      setTimeout(() => {
        this.errorEmail = false;
        this.errorMsg = '';
      }, 20000);

    }
  }

  public validEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public callbackTest(recaptchaToken: any) {
    if (recaptchaToken) {
      this.sendMail();
    }
  }

  public updateTags() {
    this.$options.dunatiHeadTags = {
      title: `${this.product.name} - Muebles de oficina | Dunati`,
      metaTags: [
        // Primaty Tags /
        {
          name: 'description',
          content: this.product.description,
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        // Open Graph / Facebook /
        {
          property: 'og:type',
          content: 'product',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/productos/${this.product.slug}`,
        },
        {
          property: 'og:title',
          content: `${this.product.name} - Muebles de oficina | Dunati`,
        },
        {
          property: 'og:description',
          content: this.product.description,
        },
        {
          property: 'og:image',
          content: `${this.product.urlImg}`,
        },
        // Twitter /
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/productos/${this.product.slug}`,
        },
        {
          property: 'og:price:amount',
          content: this.product.unitPrice,
        },
        {
          property: 'og:price:currency',
          content: 'CLP',
        },
      ],
    };
    // @ts-ignore
    this.renderTags();
  }

  public preventBehavior(e: any) {
    if (!this.scrollable) {
      e.preventDefault();
    }
  }

  public fadeIn(id: any) {
    $(`#${id}`).fadeIn(1000);
  }
}

