import { Product } from './../../class/product';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import { IItemProductB } from '@/shims-tsx';

@Component({
  components: {
    DunatiLoaderComponent,
  },
})
export default class CardProductBComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public data: Product;
  public onSale: boolean = false;
  public imageLoaded: boolean = false;
  public oldPrice: number = 0;
  public newPrice: number = 0;
  public saleImg: string = '/assets/images/product_detail/SALE.svg';

  public mounted() {
    if (this.data && this.data.webPromoPrice > 0 && (!this.data.webPromoDateInit || this.dateOnRange(this.data.webPromoDateInit, this.data.webPromoDateEnd))) {
      this.oldPrice = this.data.unitPrice;
      this.newPrice = this.data.webPromoPrice;
      this.onSale = true;
    }
  }

  public dateOnRange(d1: string, d2: string) {
    const [today, init, end] = [new Date(Date.now()), new Date(d1), new Date(d2)];
    if (today >= init && today <= end) {
      return true;
    }
    return false;
  }

  public details(slug: string) {
    this.$router.push({ name: 'products-detail', params: { id: slug } });
  }

  public getAlt() {
    return `${this.data.category.name} ${this.data.subCategory.name.toLowerCase()} de oficina, ${this.data.name.toLowerCase()}`;
  }

  public onLoaded() {
    this.imageLoaded = true;
  }
}

