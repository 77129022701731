import { ProductAddon } from './productAddon';

export class ProductAddonGroup {
  public id!: number;
  public name!: string;
  public priority!: number;
  public totalAddons!: number;

  public addons: ProductAddon[] = [];

  public urlImg = '';
  public image = '';

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.name = data.name;
      this.priority = +data.priority;

      if (data.addons) {
        data.addons.forEach((el: any) => {
          this.addons.push(new ProductAddon(el));
        });
        this.addons = this.addons.sort((a: any, b: any) => a.position - b.position);
      }

      this.totalAddons = +data.totalAddons || 0;
    }
  }
}
