import { File } from './file';

export class ProductsCertificate {
  public id!: number;
  public title!: string;
  public fileId!: number;
  public file!: File;
  // public urlImg: string = '';
  public position!: number;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.title = data.title;
      this.fileId = +data.fileId;
      this.position = +data.position;

      if (data.file) {
        this.file = new File(data.file);
      }
    }
  }
}
