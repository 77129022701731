import { ProductSubCategory } from './productSubCategory';

export class ProductCategory {
  public id!: number;
  public name!: string;
  public subCategories: ProductSubCategory[] = [];

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.name = data.name;

      if (data.subCategories) {
        this.subCategories = [];
        data.subCategories.forEach((el: any) => {
          this.subCategories.push(new ProductSubCategory(el));
        });
      }
    }
  }
}
