export class ProductBrand {
  public id!: number;
  public name!: string;

  public createdAt!: string;
  public updatedAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.name = data.name;

      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
    }
  }
}
