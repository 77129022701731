import { ProductsCertificate } from './productsCertificates';
import { ProductBrand } from './productBrand';
import { ProductCategory } from './productCategory';
import { ProductSubCategory } from './productSubCategory';
import { ProductImages } from './productImages';
import { ApiServices } from '@/services/api.services';
import { ProductAddonGroup } from './productAddonGroups';
import { File } from './file';

export class Product {
  public id!: number;
  public code!: string;
  public description!: string;
  public longDescription!: string;
  public name!: string;
  public unitPrice!: number;
  public maximumDiscount!: number;
  public initialInventory!: number;
  public stock!: number;
  public type!: string;
  public slug!: string;
  public status!: boolean;
  public webEnable!: boolean;

  public productSheetId!: number;
  public productSheet!: File;

  public fileId!: number;
  public image!: any;

  public brandId!: number;
  public brand!: ProductBrand;

  public categoryId!: number;
  public category!: ProductCategory;

  public subCategoryId!: number;
  public subCategory!: ProductSubCategory;

  public webPromoPrice!: number;
  public webPromoDateInit!: string;
  public webPromoDateEnd!: string;
  public deliveryData!: string;
  public evaluation!: number;

  public productImages: any = [];
  public productAddonGroups: any = [];
  public productCertificates: any = [];

  public urlImg = '';

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.code = data.code;
      this.description = data.description;
      this.longDescription = data.longDescription;
      this.name = data.name;
      this.unitPrice = +data.unitPrice;
      this.maximumDiscount = +data.maximumDiscount;
      this.initialInventory = +data.initialInventory;
      this.stock = +data.stock;
      this.type = data.type;
      this.status = data.status;
      this.webEnable = data.webEnable;
      this.productSheetId = +data.productSheetId;
      this.fileId = +data.fileId;
      this.brandId = +data.brandId;
      this.categoryId = +data.categoryId;
      this.subCategoryId = +data.subCategoryId;
      this.webPromoPrice = +data.webPromoPrice;
      this.webPromoDateInit = data.webPromoDateInit;
      this.webPromoDateEnd = data.webPromoDateEnd;
      this.deliveryData = data.deliveryData;
      this.evaluation = data.evaluation;
      this.slug = data.slug;

      if (data.productSheet) {
        this.productSheet = new File(data.productSheet);
      }

      if (data.image) {
        this.urlImg = ApiServices.getFileImage(data.image.key);
      } else {
        this.urlImg = '/assets/images/chair_category/imagen provisoria sillas.jpg';
      }

      if (data.brand) {
        this.brand = new ProductBrand(data.brand);
      }

      if (data.category) {
        this.category = new ProductCategory(data.category);
      }

      if (data.subCategory) {
        this.subCategory = new ProductSubCategory(data.subCategory);
      }

      if (data.images) {
        this.productImages = [];
        data.images.forEach((element: any) => {
          this.productImages.push(new ProductImages(element));
        });

        this.productImages = this.productImages.sort((a: any, b: any) => a.position - b.position);
      }

      if (data.addonGroups) {
        data.addonGroups.forEach((element: any) => {
          this.productAddonGroups.push(new ProductAddonGroup(element));
        });
      }

      if (data.certificates) {
        data.certificates.forEach((element: any) => {
          this.productCertificates.push(new ProductsCertificate(element));
        });
      }
    }
  }
}
