import { ProductAddonOptions } from './productAddonOptions';
import { File } from './file';

export class ProductAddon {
  public id!: number;
  public title!: string;
  public description!: string;
  public type!: string;
  public position!: number;

  public image!: any;
  public options: ProductAddonOptions[] = [];

  constructor(data?: any) {
    if (data) {
      this.id = +data.id;
      this.title = data.title;
      this.description = data.description;
      this.type = data.type;
      this.position = +data.position;

      if (data.file) {
        this.image = new File(data.file);
      }

      if (data.options) {
        data.options.forEach((el: any) => {
          this.options.push(new ProductAddonOptions(el));
        });
        this.options = this.options.sort((a: any, b: any) => a.position - b.position);
      }
    }
  }
}
